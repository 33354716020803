import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types'
import { Divider, Button } from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'
import IconButton from '@material-ui/core/IconButton'
import { CircularProgress } from '@material-ui/core'

const viewStyles = theme => ({
  root: {
    padding: theme.spacing(3, 3),
    margin: theme.spacing(3, 10)
  },
  toolbar: theme.mixins.toolbar,
  content: {
    marginLeft: 240,
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  servicePort: {
    marginRight: '10px',
    float: 'right',
    color: 'white',
    boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
    backgroundColor: '#66bb69',
    padding: '6px 16px',
    fontSize: '0.875rem',
    boxSizing: 'border - box',
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 500,
    lineHeight: 1.75,
    borderRadius: '4px',
    letterSpacing: '0.02857em',
    textTransform: 'uppercase'
  },
  serviceLink: {
    marginRight: '10px',
    display: 'inline-box',
    color: 'white',
    boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
    backgroundColor: '#66bb69',
    padding: '6px 16px',
    fontSize: '0.875rem',
    boxSizing: 'border - box',
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 500,
    lineHeight: 1.75,
    borderRadius: '4px',
    letterSpacing: '0.02857em',
    textTransform: 'uppercase'
  }
})

class Servers extends Component {
  state = {
    disabled: false
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    const { classes, server } = this.props

    return (
      <div>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <IconButton
            onClick={this.props.goBack}
            aria-label='delete'
            className={classes.margin}
          >
            <ArrowBack fontSize='large' />
          </IconButton>
          <Paper className={classes.root}>
            <span style={{ fontSize: '1.8rem', fontWeight: 500 }}>{server.name}</span>
            <span
              style={{
                fontStyle: 'italic',
                fontSize: '1.3rem',
                marginLeft: 20,
                color: '#66bb69'
              }}
            >
              {' '}
              {server.type}
            </span>
            {!(server.type == 'router') ? (
              <div className={classes.wrapper}>
                <Button
                  onClick={() => {
                    this.props.revertServer(server)
                    this.setState({ disabled: true })
                    this.props.loading = true
                  }}
                  variant='contained'
                  style={{ float: 'right', marginLeft: 10 }}
                  disabled={this.state.disabled || server.disabled || this.props.isAlreadyReverted(server)}
                >
                  Revert
                </Button>
                {this.props.loading && (
                  <CircularProgress
                    style={{ top: '-95%', left: '-7%%' }}
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            ) : (
              ''
            )}

            <div className={classes.servicePort}>{server.port}</div>
            <br />
            <br />
            <Divider></Divider>
            <div style={{ marginTop: 30 }}>
              <span style={{ fontSize: '1.2rem' }}> Host IP - </span>
              <span style={{ fontSize: '1rem', fontStyle: 'italic' }}> {server.hostName} </span>
              <br />
              <span style={{ fontSize: '1.2rem' }}> User - </span>
              <span style={{ fontSize: '1rem', fontStyle: 'italic' }}> {server.user} </span>
              <br />
              <div>
                <span style={{ fontSize: '1rem', fontStyle: 'italic' }}>
                  {'Instance Name - '}
                  {server.instanceName}
                </span>
                <br />
                <span style={{ fontSize: '1rem', fontStyle: 'italic' }}>
                  {'Zone - '}
                  {server.zone}
                </span>
                <br />
              </div>
            </div>
            <br />
            <br />
            <Divider></Divider>
            <div style={{ marginTop: 20 }}>
              <span style={{ fontSize: '1.5rem', fontWeight: 500 }}> Active Links </span>
              <br />
              <br />
              <br />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  flexDirection: 'column'
                }}
              >
                {server.symlinks.map(link => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginBottom: 15
                      }}
                    >
                      <span style={{ fontSize: '1.3rem', marginRight: 10 }}>{link.name} - </span>
                      <div className={classes.serviceLink}>{link.activeCommit}</div>
                    </div>
                  )
                })}
              </div>

              <br />
              <br />
            </div>
            <Divider></Divider>
            <div style={{ marginTop: 20 }}>
              <span style={{ fontSize: '1.5rem', fontWeight: 500 }}> Releases </span>
              <br />
              <br />
              <br />
              <div style={{ height: '40vh', overflowY: 'scroll', padding: '10px' }}>
                {server.releases.map(release => {
                  let myDate = new Date(release.createdAt)
                  let result = myDate.toLocaleString()
                  return (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'baseline',
                        padding: '12px',
                        justifyContent: 'normal',
                        color: 'white',
                        borderBottom: '1px solid white',
                        backgroundColor: '#66bb69'
                      }}
                    >
                      <div
                        style={{
                          fontSize: '1rem',
                          fontWeight: 600,
                          width: '52%'
                        }}
                      >
                        {release.name}
                      </div>
                      <div
                        style={{
                          fontSize: '0.8rem',
                          fontWeight: 600,
                          width: '16%',
                          textAlign: 'left'
                        }}
                      >
                        {release.hash.substr(0, 6)} ({release.branch})
                      </div>
                      <div
                        style={{
                          fontSize: '0.8rem',
                          fontWeight: 600,
                          width: '16%',
                          textAlign: 'center'
                        }}
                      >
                        {release.link}
                      </div>
                      <div
                        style={{
                          fontSize: '0.7rem',
                          textAlign: 'right',
                          width: '16%'
                        }}
                      >
                        {result}
                      </div>
                    </div>
                  )
                })}
              </div>
              <br />
              <br />
            </div>
          </Paper>
        </main>
      </div>
    )
  }
}

Servers.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(viewStyles)(Servers)
